<template>
  <b-overlay>
    <b-card class="p-lg-1 mx-5">
      <div>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon
            v-if="orderLabel.status === 'paid'"
            icon="CheckIcon"
            size="400"
            class="text-success"
          />
          <feather-icon
            v-else-if="orderLabel.status"
            icon="XCircleIcon"
            size="400"
            class="text-warning"
          />
          <b-spinner
            v-else
            style="width: 21rem; height: 21rem"
            label="Large Spinner"
            variant="primary"
          ></b-spinner>
        </div>
        <div class="d-flex justify-content-center align-items-center my-4">
          <div v-if="orderLabel.status === 'paid'">
            <h1>Payment was successful. Thank you !</h1>
            <h3 class="d-flex justify-content-center align-items-center">
              Logpoint reloaded.
            </h3>
          </div>
          <h1 v-else-if="orderLabel.status">
            Your payment was declined. Please check with your bank. Thank you !
          </h1>
          <h1 v-else>Your payment is still processing please wait.</h1>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
          v-if="orderLabel.status === 'paid'"
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          href="/log-points"
          >Continue Reloading Logpoint</b-button
        >
        <b-button
          v-else-if="orderLabel.status"
          variant="gradient-primary"
          class="box-shadow-1 px-5"
          href="/log-points"
          >Retry Reloading Logpoint</b-button
        >
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      payment: '',
      paymentStatus: '',
      orderLabel: {}
    }
  },
  mounted() {
    setTimeout(() => {
      this.$http
        .get(`logpoint/payment_status/${this.$route.params.id}`)
        .then((response) => {
          if (response.status == 200) {
            this.orderLabel = response.data.data
            this.isLoading = false
          }
        })
    }, 10000)
  },
  methods: {
    checkPayment() {
      this.$http
        .get(`check_order_payment_status/${this.orderLabel.id}`)
        .then((response) => {
          this.payment = response.data.data.status
          this.paymentStatus = response.data.data.remarks
        })
    },
    generateLabel() {
      this.$refs['generateLabel'].show()
    },

    downloadInvoice() {
      this.$refs['downloadInvoice'].show()
    },

    continueOrdering() {
      this.$router.replace('/outlet-order')
    },

    retryPay() {
      this.$http
        .post('pay_logpoints', { total_charge: this.orderLabel.price })
        .then((response) => {
          location.href = response.data.url
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-width: 3px !important;
}
</style>
